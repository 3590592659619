<template>
  <div>
    <section class="content">
      <div class="row">
        <div class="col-sm-12">
          <div class="box box-default box-solid box-component">
            <div class="box-body box-details-header">
              <div class="details-header">
                <div class="details-header--details">
                  <h2>Dane komunikatu systemowego</h2>
                  <ul
                    v-if="maintenanceInfo"
                    class="list-unstyled"
                  >
                    <li><label>Nazwa:</label> {{ maintenanceInfo.label }}</li>
                    <li><label>Tekst komunikatu:</label> {{ maintenanceInfo.infoText }}</li>
                    <li><label>Typ:</label> {{ maintenanceInfoType }}</li>
                    <li><label>Role:</label> {{ maintenanceInfoRoles }}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Modals -->
    <MaintenanceInfoEditModal
      :show="maintenanceInfoEditVisible"
      :form-data="maintenanceInfo"
      @close="maintenanceInfoEditVisible = false"
      @success="maintenanceInfoEdited"
    />
  </div>
</template>

<script>
import api from '../../../../../api'
import DynamicBreadcrumb from '../../../share/mixins/DynamicBreadcrumb'
import MaintenanceInfoEditModal from './modal/MaintenanceInfoEditModal'
import Page from '../../../../share/content/Page'
import ErrorNotify from '../../../../share/mixins/ErrorNotify'

export default {
  components: {
    MaintenanceInfoEditModal
  },
  mixins: [
    DynamicBreadcrumb,
    Page,
    ErrorNotify
  ],
  data () {
    return {
      events: {
        editMaintenanceInfo: 'core:maintenanceInfo:editMaintenanceInfo'
      },
      headerTitle: { title: 'Komunikat informacyjny', description: 'Szczegóły' },
      maintenanceInfoEditVisible: false,
      actionEditName: 'maintenanceInfo_edit',
      urlActions: {
        getContent: { method: 'get', url: `/maintenance-info/${this.$route.params.id}` }
      },
      maintenanceInfo: null,
      roles: []
    }
  },
  computed: {
    maintenanceInfoRoles () {
      if (this.maintenanceInfo && this.maintenanceInfo.roles && this.roles.length !== 0) {
        return this.maintenanceInfo.roles.map(role => role.label).join(', ') + '.'
      } else {
        return []
      }
    },
    maintenanceInfoType () {
      if (this.maintenanceInfo && this.maintenanceInfo.roles && this.roles.length !== 0) {
        return this.maintenanceInfo.infoType.label
      } else {
        return ''
      }
    }
  },
  mounted () {
    this.$events.on(this.events.editMaintenanceInfo, this.editMaintenanceInfo)

    this.$nextTick(() => {
      this.loadContent()
    })
  },
  methods: {
    editMaintenanceInfo () {
      this.maintenanceInfoEditVisible = true
    },
    loadContent () {
      this.toggleLoading()

      api.request('core', this.urlActions.getContent.method, this.urlActions.getContent.url)
        .then((response) => {
          this.toggleLoading()
          let data = response.data
          this.getRolesAndTypes(data)

          // dynamic breadcrumb labels
          this.addDynamicBreadcrumbLabel(this.$route.name, this.maintenanceInfo.label)

          this.dataLoaded = true
        })
        .catch(() => {
          this.toggleLoading()
        })
    },
    getRolesAndTypes (data) {
      api.request('core', 'get', `/roles`)
        .then((request) => {
          let maintenanceInfo = data
          this.roles = request.data
          if (maintenanceInfo.length !== 0 && maintenanceInfo.roles.length !== 0) {
            let rolesMap = maintenanceInfo.roles.map(role => this.roles.filter(element => element.id === role)[0])
            maintenanceInfo['roles'] = rolesMap
          }
          let types = [
            { value: 1, label: 'Informacja' },
            { value: 2, label: 'Ostrzeżenie' }
          ]
          maintenanceInfo['infoType'] = types.filter(type => type.value.toString() === maintenanceInfo.infoType)[0]
          this.maintenanceInfo = maintenanceInfo
        })
        .catch(e => {
          let maintenanceInfo = data

          let types = [
            { value: 1, label: 'Informacja' },
            { value: 2, label: 'Ostrzeżenie' }
          ]
          maintenanceInfo['infoType'] = types.filter(type => type.value.toString() === maintenanceInfo.infoType)[0]
          this.maintenanceInfo = maintenanceInfo

          this.errorNotify(e)
        })
    },
    maintenanceInfoEdited () {
      this.loadContent()
    }
  }
}
</script>

<style scoped>
    .label {
        display: inline-block;
        margin-bottom: 0.3em;
        vertical-align: middle;
    }
</style>
