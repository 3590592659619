<script>
import FormModalMixin from '../../../../../share/modal/FormModalMixin'
import FormModal from '../form/MaintenanceInfoEdit'
import api from '../../../../../../api'

export default {
  components: {
    FormModal
  },
  mixins: [
    FormModalMixin
  ],
  data () {
    return {
      title: 'Edycja danych Komunikatu administracyjnego',
      okText: 'Zmień'
    }
  },
  methods: {
    submitForm () {
      api.request('core', 'put', `/maintenance-info/${this.$route.params.id}`, this.createDataToSend())
        .then(() => {
          this.toggleLoading()

          this.$emit('success', this.model)
          this.$emit('close')
          this.$notify({
            type: 'success',
            text: 'Zaktualizowano dane komunikatu informacyjnego'
          })
        })
        .catch((error) => {
          this.toggleLoading()
          this.errorNotify(error)
        })
    },
    createDataToSend () {
      return {
        label: this.model.label,
        infoText: this.model.infoText,
        infoType: this.model.infoType.value,
        roles: this.model.roles.length > 0 && this.model.roles.map(role => role.id)
      }
    }
  }
}
</script>
